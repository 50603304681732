import { Component, OnInit } from '@angular/core';
import {
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router,
  RouterEvent
} from '@angular/router';

import { PageTitleService } from '@core-module/services';

@Component({
  selector: 'em-root',
  templateUrl: './app.component.html',
  styleUrls: [ './app.component.scss' ]
})
export class AppComponent implements OnInit {
  pageLoading: boolean = false;
  showFooter: boolean;
  year: number;

  constructor(private _titleService: PageTitleService, private _router: Router) {
    const d = new Date();
    this.year = d.getFullYear();
  }

  public ngOnInit() {
    this._titleService.init();
    document.getElementById('preload-css').remove();

    this._router.events.subscribe((event: RouterEvent) => {
      switch (true) {
        case event instanceof NavigationStart: {
          this.pageLoading = true;
          break;
        }
        case event instanceof NavigationEnd: {
          this.pageLoading = false;
          this.showFooter = event.url !== '/';
          break;
        }
        case event instanceof NavigationCancel:
        case event instanceof NavigationError: {
          this.pageLoading = false;
          break;
        }
        default: {
          break;
        }
      }
    });
  }
}
