<div class="main-content">
  <em-page-hero pageTitle="About me." backgroundImage="about-me" backgroundColor="secondary"></em-page-hero>

  <section class="content">
    <p class="lead-paragraph">I'm Elizabeth and I am an experienced Lead Front End Developer whose career has focussed on building high performance websites and applications for multiple international clients.</p>

    <p class="lead-paragraph">I specialise in creating beautiful and responsive websites and apps using HTML, CSS, Javascript, NodeJS and Python and front end frameworks such as Angular.</p>

    <p>I've had the privilege of working with some great international clients around the world such as Accenture, IHS Markit, Sprint, Wall Street Journal Property, Mail Travel and the BGL Group with a variety of different technologies which had all lead to a diverse and incredibly valuable level of experience.</p>

    <p>From a young age I've had a fascination for all things involving technology and in particular computers. It was during my degree studies that I learnt about web development and I gained a passion for all things involving the internet and web development. I graduated from Queen Mary University of London with a BSc degree in Information, Communication and Technology and immediately began freelancing for a few years under the name <strong>JoMad Creative</strong>. Freelancing let me see all the different aspects of a project life cycle, I found clients, went through requirements gathering, set and met time lines, designed websites and apps, created logic and functionality and developed bespoke websites using HTML, CSS, Javascript and Python.</p>

    <p>My development process often, if not always, involves working with provided or created flat designs and wireframes. When it comes to working on a fresh new project, that's where my passion lies - doing the research, working with designers, slicing up the designs to living breathing websites and making it all happen. A successful project to me is reached when one idea is carried throughout all the stages of a project life cycle. I love to create pixel perfect websites, which must come from my meticulous eye and high attention to detail. During my working process, I make sure that the website is optimised, responsive, cross-browser compatible, and accessible for the client and search engines. Although I mainly work with front end technologies, I know my way around back end technologies such as PHP, Ruby on Rails, Java, Python.</p>
  </section>
</div>
