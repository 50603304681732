<div class="navbar">
  <div class="navbar-toggle" aria-controls="navbarText" aria-expanded="false" aria-label="Toggle navigation">
    <a routerLink="/" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" class="nav-link">
      <img src="assets/images/logo.svg" alt="Elizabeth Maduka">
    </a>
  </div>

  <nav class="nav">
    <div>
      <a routerLink="/about-me" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" class="nav-link">About<br>me</a>
      <a routerLink="/work-history" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" class="nav-link">Work<br>history</a>
      <a routerLink="/skills" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" class="nav-link">Skills</a>
      <a routerLink="/contact-me" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" class="nav-link">Contact<br>me</a>
    </div>
  </nav>

  <div class="external-links">
    <a href="https://www.linkedin.com/in/emaduka" target="_blank" class="linkedin" title="LinkedIn Elizabeth Maduka"></a>
    <a href="https://vimeo.com/110246262" target="_blank" class="digital-women" title="Digital Women UK Interviews Irene and Elizabeth Maduka"></a>
  </div>
</div>
