<div class="main-content">
  <em-page-hero pageTitle="Work history." backgroundColor="primary"></em-page-hero>

  <div class="content">
    <section class="contract-details" *ngFor="let client of clients">
      <div class="meta-data">
        <div class="date-and-role">
          <h1 class="role title bold">{{client.role}}</h1>
          <h2 class="role-dates sub-title">{{client.startDate}} - {{client.endDate || 'present'}}</h2>
        </div>

        <img [src]="'/assets/images/clients/' + client.logo" [alt]="client.name">
      </div>

      <div class="projects">
        <div class="project" *ngFor="let project of client.projects">
          <h3 class="sub-heading" *ngIf="project.title">{{project.title}}</h3>
          <ul>
            <li *ngFor="let detail of project.details">{{detail}}</li>
          </ul>
        </div>
      </div>
    </section>
  </div>
</div>
