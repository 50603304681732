import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { switchMap, filter, map } from 'rxjs/operators';

const APP_TITLE = 'Elizabeth Maduka, Front End / UI Developer';

@Injectable({
  providedIn: 'root'
})
export class PageTitleService {
  constructor(
    private _titleService: Title,
    private _router: Router,
    private _activatedRoute: ActivatedRoute
  ) {
  }

  public init() {
    this._router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      map(() => this._activatedRoute),
      map(route => route.firstChild),
      switchMap(route => route.data),
      // eslint-disable-next-line consistent-return
      map((data) => {
        if (data.title) {
          // If the route has a title set in the app.routing module
          return data.title;
        }
      })
    ).subscribe(pathString => {
      if (pathString) {
        this._titleService.setTitle(`${ pathString } || ${ APP_TITLE }`);
      } else {
        this._titleService.setTitle(APP_TITLE);
      }
    });
  }
}
