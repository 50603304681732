import { Component, ElementRef, EventEmitter, HostBinding, HostListener, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { coerceBooleanProperty, coerceNumberProperty } from '@angular/cdk/coercion';
import { trigger } from '@angular/animations';
import { Subject, Subscription } from 'rxjs';
import { startWith, delay, filter, takeWhile } from 'rxjs/operators';

import { CustomAnimationSpeed, CustomAnimationType } from './animate.interface';
import { AnimateService } from './animate.service';
import { beat, bounce, headShake, heartBeat, pulse, rubberBand, shake, swing, wobble, jello, tada, flip } from './attention-seekers';
import { bumpIn, bounceIn, fadeIn, flipOn, jackInTheBox, landing, rollIn, zoomIn } from './entrances';
import { bounceOut, fadeOut, hinge, rollOut, zoomOut } from './exits';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: '[emAnimate]',
  templateUrl: './animate.component.html',
  animations: [
    trigger('animate',
      [
        // Attention seekers
        ...beat, ...bounce, ...flip, ...headShake, ...heartBeat, ...jello, ...pulse, ...rubberBand, ...shake, ...swing, ...tada, ...wobble,
        // Entrances
        ...bumpIn, ...bounceIn, ...fadeIn, ...flipOn, ...jackInTheBox, ...landing, ...rollIn, ...zoomIn,
        // Exits
        ...bounceOut, ...fadeOut, ...hinge, ...rollOut, ...zoomOut
      ]
    ) ]
})
export class AnimateComponent implements OnInit, OnDestroy {
  // Animating properties
  public animating = false;
  public animated = false;

  //
  // Public properties
  /** Selects the animation to be played */
  @Input('emAnimate') animate: CustomAnimationType;

  /** Speeds up or slows down the animation, turns the requested speed into a valid timing */
  @Input() set speed(speed: CustomAnimationSpeed) {
    this._speed = { slower: '3s', slow: '2s', normal: '1s', fast: '500ms', faster: '300ms' }[speed || 'normal'];
  }

  /** Delays the animation */
  @Input()
  get delay(): string {
    return this._delay;
  }

  set delay(val: string) {
    // Coerces the input into a number first
    const value = coerceNumberProperty(val, 0);

    if (value) {
      // Turns a valid number into a ms delay
      this._delay = `${ value }ms`;
    } else {
      // Test the string for a valid delay combination
      this._delay = /^\d+(?:ms|s)$/.test(val) ? val : '';
    }
  }

  /** Disables the animation */
  @Input()
  get disabled(): boolean {
    return this._disabled;
  }

  set disabled(value: boolean) {
    this._disabled = coerceBooleanProperty(value);
  }

  /** When true, keeps the animation idle until the next replay triggers */
  @Input()
  get paused(): boolean {
    return this._paused;
  }

  set paused(value: boolean) {
    this._paused = coerceBooleanProperty(value);
  }

  /** When defined, triggers the animation on element scrolling in the viewport by the specified amount. Amount defaults to 50% when not specified */
  @Input('aos')
  get threshold(): number {
    return this._threshold;
  }

  set threshold(value: number) {
    this._threshold = coerceNumberProperty(value, 0.5);
  }

  /** When true, triggers the animation on element scrolling in the viewport */
  @Input()
  get once(): boolean {
    return this._once;
  }

  set once(value: boolean) {
    this._once = coerceBooleanProperty(value);
  }

  /** Replays the animation */
  @Input() set replay(replay: any) {
    // Re-triggers the animation again on request (skipping the very fist value)
    if (!!this._trigger && coerceBooleanProperty(replay)) {

      this._trigger = this._idle;
      this._replay$.next(true);
    }
  }

  /** Emits at the end of the animation */
  @Output() public start: EventEmitter<void> = new EventEmitter<void>();
  /** Emits at the end of the animation */
  @Output() public done = new EventEmitter<void>();


  //
  // Private properties
  @HostBinding('@.disabled') private _disabled = false;
  @HostBinding('@animate') private _trigger;

  private _replay$ = new Subject<boolean>();
  private _sub: Subscription;

  // Animating parameters
  private _speed: string;
  private _delay: string;

  private _paused: boolean = false;
  private _threshold: number = 0;
  private _once: boolean = false;

  private get _idle() {
    return { value: `idle-${ this.animate }` };
  }

  private get _play() {
    const params = {};
    // Builds the params object, so, leaving to the default values when undefined

    if (!!this._speed) {
      params['timing'] = this._speed;
    }
    if (!!this._delay) {
      params['delay'] = this._delay;
    }

    return { value: this.animate, params };
  }

  constructor(private _elementRef: ElementRef, private _animateService: AnimateService) {}

  public ngOnInit(): void {
    // Sets the idle state for the given animation
    this._trigger = this._idle;
    // Triggers the animation based on the input flags
    this._sub = this._replay$.pipe(
      // Waits the next round to re-trigger
      delay(0),
      // Triggers immediately when not paused
      startWith(!this._paused),
      // Builds the AOS (Animate on Scroll) observable from the common service
      this._animateService.trigger(this._elementRef, this._threshold),
      // Prevents false visibility blinks due to the animation transformations
      filter(() => !this.animating),
      // Stops after the first on trigger when 'once' is set
      takeWhile(t => !t || !this.once, true)
    ).subscribe(t => {
      // Triggers the animation to play or to idle
      this._trigger = t ? this._play : this._idle;
    });
  }

  public ngOnDestroy() {
    this._sub.unsubscribe();
  }

  @HostListener('@animate.start')
  private _animationStart() {
    this.animating = true;
    this.animated = false;
    this.start.emit();
  }

  @HostListener('@animate.done')
  private _animationDone() {
    this.animating = false;
    this.animated = true;
    this.done.emit();
  }
}
