import { NgModule } from '@angular/core';
import { PageTitleService } from './services/page-title-service/page-title.service';
import { SharedModule } from '../shared/shared.module';

@NgModule({
  declarations: [],
  imports: [
  ],
  exports: [
    SharedModule
  ],
  providers: [
    PageTitleService
  ]
})
export class CoreModule { }
