<div class="main-content">
  <em-page-hero pageTitle="Skills." backgroundColor="quaternary"></em-page-hero>

  <div class="content">
    <p>These are the main technical areas of expertise that I work within.</p>

    <hr class="secondary">

    <h1 class="sub-title">Front end</h1>
    <div class="logos">
      <img src="/assets/images/skills/html5.svg" alt="HTML 5" title="HTML 5">
      <img src="/assets/images/skills/javascript.png" alt="JavaScript" title="JavaScript">
      <img src="/assets/images/skills/angular.svg" alt="Angular" title="Angular">
      <img src="/assets/images/skills/angular-material.svg" alt="Angular Material" title="Angular Material">
      <img src="/assets/images/skills/jasmine.svg" alt="Jasmine" title="Jasmine" class="wide">
      <img src="/assets/images/skills/karma.png" alt="Karma" title="Karma" class="wide">
    </div>

    <h1 class="sub-title">CSS</h1>
    <div class="logos">
      <img src="/assets/images/skills/css3.svg" alt="CSS 3" title="CSS 3">
      <img src="/assets/images/skills/sass.png" alt="SCSS/SASS" title="SCSS/SASS">
      <img src="/assets/images/skills/less.png" alt="Less" title="Less" class="wide">
      <img src="/assets/images/skills/bootstrap.svg" alt="Bootstrap" title="Bootstrap">
    </div>

    <h1 class="sub-title">Back end</h1>
    <div class="logos">
      <img src="/assets/images/skills/node-js.svg" alt="Node JS" title="Node JS">
      <img src="/assets/images/skills/php.svg" alt="PHP" title="PHP">
      <img src="/assets/images/skills/python.svg" alt="Python" title="Python" class="wide">
    </div>

    <h1 class="sub-title">Data</h1>
    <div class="logos">
      <img src="/assets/images/skills/mysql.svg" alt="MySQL" title="MySQL">
      <img src="/assets/images/skills/mongodb.png" alt="Mongo DB" title="Mongo DB" class="wide">
    </div>

    <h1 class="sub-title">Design</h1>
    <div class="logos">
      <img src="/assets/images/skills/illustrator.svg" alt="Adobe Illustrator" title="Adobe Illustrator">
      <img src="/assets/images/skills/photoshop.svg" alt="Adobe Photoshop" title="Adobe Photoshop">
      <img src="/assets/images/skills/sketch.svg" alt="Sketch" title="Sketch">
    </div>

    <h1 class="sub-title">Cloud</h1>
    <div class="logos">
      <img src="/assets/images/skills/aws.png" alt="AWS" title="AWS">
    </div>

    <h1 class="sub-title">Development tools</h1>
    <div class="logos">
      <img src="/assets/images/skills/git.svg" alt="Git" title="Git" class="wide">
      <img src="/assets/images/skills/github.png" alt="GitHub" title="GitHub" class="wide">
      <img src="/assets/images/skills/gitlab.svg" alt="GitLab" title="GitLab" class="wide">
      <img src="/assets/images/skills/azure-devops.png" alt="Azure DevOps" title="Azure DevOps">
    </div>
  </div>
</div>
