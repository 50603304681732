import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { NavComponent } from './components/nav/nav.component';
import { StyledButtonComponent } from './components/styled-button/styled-button.component';
import { PageHeroComponent } from './components/page-hero/page-hero.component';
import { AnimateModule } from '../animate/animate.module';

@NgModule({
  declarations: [
    NavComponent,
    StyledButtonComponent,
    PageHeroComponent
  ],
  imports: [
    BrowserModule,
    CommonModule,
    RouterModule,
    BrowserAnimationsModule,
    AnimateModule
  ],
  exports: [
    BrowserModule,
    CommonModule,
    RouterModule,
    AnimateModule,
    NavComponent,
    StyledButtonComponent,
    BrowserAnimationsModule,
    PageHeroComponent
  ]
})
export class SharedModule {
}
