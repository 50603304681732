import { Component, Input, OnInit } from '@angular/core';
import { ThemeOptions } from '@shared-module/components/styled-button/styled-button.interface';

@Component({
  selector: 'em-page-hero',
  templateUrl: './page-hero.component.html',
  styleUrls: [ './page-hero.component.scss' ]
})
export class PageHeroComponent implements OnInit {
  @Input() pageTitle: string;
  @Input() subTitle: string;
  @Input() backgroundColor: ThemeOptions;
  @Input() backgroundImage: 'page-not-found';

  public ngOnInit(): void {
    this.backgroundColor = this.backgroundColor || 'primary';
  }
}
