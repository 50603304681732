<div class="main-content">
  <em-page-hero pageTitle="Media Credits."></em-page-hero>

  <section class="content">
    <p>Thanks for the amazing designers and photographers whose work I made use of in this website.</p>

    <ul>
      <li>
        <span>Photo by <a href="https://unsplash.com/@ilumire?utm_source=unsplash&amp;utm_medium=referral&amp;utm_content=creditCopyText">Jelleke Vanooteghem</a> on <a href="https://unsplash.com/?utm_source=unsplash&amp;utm_medium=referral&amp;utm_content=creditCopyText">Unsplash</a></span>
      </li>

      <li>Icons made by <a href="https://www.flaticon.com/authors/pixel-perfect" title="Pixel perfect">Pixel perfect</a> from <a href="https://www.flaticon.com/" title="Flaticon"> www.flaticon.com</a>
      </li>

      <li>Icons made by <a href="https://www.flaticon.com/authors/freepik" title="Freepik">Freepik</a> from <a href="https://www.flaticon.com/" title="Flaticon"> www.flaticon.com</a></li>
    </ul>
  </section>
</div>
