import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { HomeComponent } from './routes/home/home.component';
import { AboutMeComponent } from './routes/about-me/about-me.component';
import { PageNotFoundComponent } from './routes/page-not-found/page-not-found.component';
import { WorkHistoryComponent } from './routes/work-history/work-history.component';
import { ContactMeComponent } from './routes/contact-me/contact-me.component';
import { CreditsComponent } from './routes/credits/credits.component';
import { SkillsComponent } from './routes/skills/skills.component';

const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
    pathMatch: 'full'
  },
  {
    path: 'about-me',
    component: AboutMeComponent,
    data: {
      title: 'About Me',
      pageClass: 'about-me'
    }
  },
  {
    path: 'work-history',
    component: WorkHistoryComponent,
    data: {
      title: 'Work History',
      pageClass: 'work-history'
    }
  },
  {
    path: 'contact-me',
    component: ContactMeComponent,
    data: {
      title: 'Contact Me',
      pageClass: 'contact-me'
    }
  },
  {
    path: 'skills',
    component: SkillsComponent,
    data: {
      title: 'Skills',
      pageClass: 'skills'
    }
  },
  {
    path: 'credits',
    component: CreditsComponent,
    data: {
      title: 'Credits',
      pageClass: 'credits'
    }
  },
  {
    path: '**',
    component: PageNotFoundComponent
  }
];

@NgModule({
  imports: [ RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' }) ],
  exports: [ RouterModule ]
})
export class AppRoutingModule {
}
