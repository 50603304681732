<div class="main-content">
  <em-page-hero pageTitle="Contact me." backgroundColor="tertiary"></em-page-hero>

  <section class="content">
    <p class="lead-paragraph">Let’s create something beautiful.</p>

    <p>I'm always looking for new opportunities to collaborate with start-ups / existing companies / other developers / innovators / consultancies.  I love working with other passionate individuals and vibing off each other to produce something amazing and revolutionary so drop me a line at <a href="mailto:me@elizabethmaduka.com">me@elizabethmaduka.com</a> with the details and a way to get back in touch with you.</p>

    <p>Let’s chat.</p>

    <hr class="tertiary">

    <p class="sub-title">Download my <a download="elizabeth-maduka-cv.pdf" href="/assets/files/elizabeth-maduka-cv.pdf">CV</a>.</p>
  </section>
</div>
