import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { Router } from '@angular/router';
import * as Sentry from '@sentry/angular-ivy';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SharedModule } from './shared/shared.module';
import { CoreModule } from './core/core.module';

import { AboutMeComponent } from './routes/about-me/about-me.component';
import { HomeComponent } from './routes/home/home.component';
import { PageNotFoundComponent } from './routes/page-not-found/page-not-found.component';
import { WorkHistoryComponent } from './routes/work-history/work-history.component';
import { ContactMeComponent } from './routes/contact-me/contact-me.component';
import { CreditsComponent } from './routes/credits/credits.component';
import { SkillsComponent } from './routes/skills/skills.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    AboutMeComponent,
    PageNotFoundComponent,
    WorkHistoryComponent,
    ContactMeComponent,
    CreditsComponent,
    SkillsComponent
  ],
  imports: [
    AppRoutingModule,
    SharedModule,
    CoreModule,
    HttpClientModule
  ],
  bootstrap: [ AppComponent ],
  providers: [
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler()
    },
    {
      provide: Sentry.TraceService,
      deps: [ Router ]
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [ Sentry.TraceService ],
      multi: true
    }
  ]
})
export class AppModule {
}
