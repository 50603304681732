import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { ApiAbstractService } from '@core-module/services';
import { Client } from './clients-api.interface';

@Injectable({
  providedIn: 'root'
})
export class ClientsApiService extends ApiAbstractService {
  constructor(protected httpClient: HttpClient) {
    super(httpClient, { endpoint: '' });
  }

  public clients(): Observable<{ clients: Client[] }> {
    return this.get<{ clients: Client[] }, null>('clients.json');
  }
}
