import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

import { ApiRequestOptions } from './api.interface';

export abstract class ApiAbstractService {
  protected constructor(protected httpClient: HttpClient, protected requestOptions?: ApiRequestOptions) {
  }

  protected get<T, P>(additionalPath?: string, queryParams?: P): Observable<T> {
    return this.httpClient.get<T>(this._constructUrl(additionalPath), this._getOptions(queryParams));
  }

  protected post<T, B, P>(additionalPath: string, body: B, queryParams?: P): Observable<T> {
    return this.httpClient.post<T>(
      this._constructUrl(additionalPath),
      body,
      this._getOptions(queryParams)
    );
  }

  protected patch<T, B, P>(additionalPath: string, body: B, queryParams?: P): Observable<T> {
    return this.httpClient.patch<T>(
      this._constructUrl(additionalPath),
      body,
      this._getOptions(queryParams)
    );
  }

  protected put<T, B, P>(additionalPath: string, body: B, queryParams?: P): Observable<T> {
    return this.httpClient.put<T>(
      this._constructUrl(additionalPath),
      body,
      this._getOptions(queryParams)
    );
  }

  protected delete<T>(additionalPath: string, id: string, queryParams: any): Observable<T> {
    return this.httpClient.delete<T>(this._constructUrl(additionalPath), this._getOptions(queryParams));
  }

  private _constructUrl(path?: string): string {
    return `/data/${path ? '/' + path : ''}`;
  }

  private _getOptions(params) {
    const options = {};
    let queryParams = new HttpParams();

    if (params) {
      Object.keys(params).forEach(key => (queryParams = queryParams.set(key, params[key])));
      options['params'] = queryParams;
    }

    return options;
  }
}
