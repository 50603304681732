<em-nav></em-nav>

<div class="page-loading-spinner" *ngIf="pageLoading">
  <svg class="loader" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 340 340">
    <circle cx="170" cy="170" r="160" stroke="#0f9b8f"/>
    <circle cx="170" cy="170" r="135" stroke="#212529"/>
    <circle cx="170" cy="170" r="110" stroke="#0f9b8f"/>
    <circle cx="170" cy="170" r="85" stroke="#212529"/>
  </svg>
</div>

<router-outlet></router-outlet>

<footer *ngIf="showFooter">
  <small>Copyright &copy; {{year}} Elizabeth Maduka and <a href="http://www.7digitallabs.co.uk" title="7 Digital Labs" target="_blank">7 Digital Labs</a>. <br>All rights reserved.</small>

  <small><a routerLink="/credits" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Media credits</a></small>
</footer>
