import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'em-page-not-found',
  templateUrl: './page-not-found.component.html'
})
export class PageNotFoundComponent implements OnInit {
  pageTitle: string;
  subTitle: string;

  public ngOnInit() {
    this.pageTitle = 'Oops';
    this.subTitle = 'Stay calm and <em>don\'t</em> freak out!!';
  }
}
