import { NgModule } from '@angular/core';

import { AnimateComponent } from './animate.component';
import { CommonModule } from '@angular/common';

@NgModule({
  declarations: [ AnimateComponent ],
  imports: [ CommonModule ],
  exports: [ AnimateComponent ]
})
export class AnimateModule {
}
