import { Component } from '@angular/core';
import { Client, ClientsApiService } from '@shared-module/services';

@Component({
  selector: 'em-work-history',
  templateUrl: './work-history.component.html',
  styleUrls: [ './work-history.component.scss' ]
})
export class WorkHistoryComponent {
  clients: Client[];

  constructor(private _clientsApiService: ClientsApiService) {
    this._clientsApiService.clients().subscribe(clients => this.clients = clients.clients);
  }
}
